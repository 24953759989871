import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faSpinner,
  faChevronRight,
  faHome,
  faLocationArrow,
  faPhoneAlt,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons"

import { faCalendarAlt, faFilePdf } from "@fortawesome/free-regular-svg-icons"

import { faFacebookF } from "@fortawesome/free-brands-svg-icons"

library.add(
  faSpinner,
  faChevronRight,
  faHome,
  faLocationArrow,
  faPhoneAlt,
  faAngleRight,
  faCalendarAlt,
  faFilePdf,
  faFacebookF
)

const Icon = ({ prefix, name, spin }) => {
  if (spin === true) {
    return <FontAwesomeIcon icon={[prefix, name]} spin />
  } else {
    return <FontAwesomeIcon icon={[prefix, name]} />
  }
}

export default Icon
